import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'

// import Swiper React components
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'

const PricingSlider = () => {
  const [checked, setChecked] = useState(true)
  const ref = useRef(null)

  const price_number_height = () => {
    const prices = ref.current.querySelectorAll('.price')

    if (prices.length < 1) return

    Array.from(prices).forEach((price) => {
      const month = price.querySelector('.month')
      const year = price.querySelector('.year')

      price.style.height = Math.max(month.offsetHeight, year.offsetHeight) - 2 + 'px'
      // price.style.width = Math.max(month.offsetWidth, year.offsetWidth) + 'px';
    })
  }

  useEffect(() => {
    price_number_height()
    window.addEventListener('resize', price_number_height)

    return () => {
      window.removeEventListener('resize', price_number_height)
    }
  }, [])

  useEffect(() => {
    const prices = ref.current.querySelectorAll('.price')

    if (prices.length < 1) return

    if (checked) {
      Array.from(prices).forEach((price) => {
        if (price === undefined || price === null) return
        price.classList.remove('price-month')
      })
    } else {
      Array.from(prices).forEach((price) => {
        if (price === undefined || price === null) return
        price.classList.add('price-month')
      })
    }
  }, [checked])

  return (
    <div className='pricing'>
      <div className='row'>
        <div className='col'>
          <div className='switch'>
            <div className='form-check form-switch'>
              <label className='form-check-label' htmlFor='price-switch'>
                VNĐ
              </label>
              <input
                className='form-check-input'
                type='checkbox'
                id='price-switch'
                onChange={() => {
                  setChecked(!checked)
                }}
                defaultChecked={checked}
              />
              <label className='form-check-label' htmlFor='price-switch'>
                USD
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <div className='pricing-slider' ref={ref}>
            <Swiper
              className='swiper-container'
              resizeObserver={true}
              breakpoints={{
                0: {
                  enabled: true,
                  centeredSlides: true,
                  slidesPerView: 1.2,
                  spaceBetween: 30,
                  initialSlide: 1,
                },
                400: {
                  enabled: true,
                  centeredSlides: true,
                  slidesPerView: 1.4,
                  spaceBetween: 30,
                  initialSlide: 1,
                },
                576: {
                  enabled: true,
                  centeredSlides: true,
                  slidesPerView: 1.5,
                  spaceBetween: 30,
                  initialSlide: 1,
                },
                768: {
                  enabled: true,
                  centeredSlides: true,
                  slidesPerView: 1.8,
                  spaceBetween: 30,
                  initialSlide: 1,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                  enabled: false,
                  centeredSlides: false,
                },
              }}
              pagination={false}
            >
              <SwiperSlide>
                <div className='pricing-single basic'>
                  <h5 className='plan'>Silver</h5>
                  <div className='price price-month'>
                    <div className='month'>
                      $<span className='number'>25</span>
                    </div>
                    <div className='year'>
                      $<span className='number'>25</span>
                    </div>
                  </div>
                  <Link to='/contact' className='button button-basic'>
                    <div className='button-inner'>
                      <div className='button-content'>
                        <h4>View Now</h4>
                      </div>
                    </div>
                  </Link>
                  <ul>
                    <li className='available'>There are discounts on services available.</li>
                  </ul>
                  <h6>Automatically activated.</h6>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='pricing-single standard'>
                  <h5 className='plan'>Gold</h5>
                  <div className='price price-month'>
                    <div className='month'>
                      $<span className='number'>425</span>
                    </div>
                    <div className='year'>
                      $<span className='number'>425</span>
                    </div>
                  </div>
                  <Link to='/contact' className='button button-standard'>
                    <div className='button-inner'>
                      <div className='button-content'>
                        <h4>View Now</h4>
                      </div>
                    </div>
                  </Link>
                  <ul>
                    <li className='available'>There are discounts on services available.</li>
                  </ul>
                  <h6>Automatically activated</h6>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='pricing-single premium'>
                  <h5 className='plan'>Platinum</h5>
                  <div className='price price-month'>
                    <div className='month'>
                      $<span className='number'>2.125</span>
                    </div>
                    <div className='year'>
                      $<span className='number'>2.125</span>
                    </div>
                  </div>
                  <Link to='/contact' className='button button-premium'>
                    <div className='button-inner'>
                      <div className='button-content'>
                        <h4>View Now</h4>
                      </div>
                    </div>
                  </Link>
                  <ul>
                    <li className='available'>There are discounts on services available.</li>
                  </ul>
                  <h6>Automatically activated</h6>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PricingSlider
