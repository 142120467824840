const features = [
  {
    icon: 'las la-money-bill-wave',
    heading: 'Affordable Pricing',
    excerpt: 'Offering cost-effective solutions, our SMM panel helps you save on social media promotions.',
    to: null,
  },
  {
    icon: 'las la-user-friends',
    heading: 'User-Friendly Interface',
    excerpt: 'Navigate easily with our intuitive dashboard, streamlining your social media account management.',
    to: null,
  },
  {
    icon: 'las la-headset',
    heading: '24/7 Support',
    excerpt: 'Get quick assistance for any inquiries or issues with our around-the-clock support team.',
    to: null,
  },
  {
    icon: 'las la-server',
    heading: 'Technology',
    excerpt: 'Leverage our automated, cutting-edge system to simplify your social media growth and management.',
    to: null,
  },
  {
    icon: 'las la-user-lock',
    heading: 'Security',
    excerpt: "Your account's security is paramount; we never compromise or share your information.",
    to: null,
  },
  {
    icon: 'las la-rocket',
    heading: 'Services',
    excerpt: 'Access a broad spectrum of services across platforms like Facebook, Tiktok, and more.',
    to: null,
  },
]

export default features
