import React from 'react'

const DownloadButton = ({ groupType, buttonType, color }) => {
  return (
    <div className={`download-button-group ${groupType}`}>
      <a href='https://app.likev3.com/login' className={`download-button download-button-google ${buttonType}`}>
        <div className={`download-button-inner ${color}`}>
          <div className={`download-button-icon c-orange ${color}`}>
            <i className='fa fa-user'></i>
          </div>
          <div className='download-button-content'>
            <h5 className={`c-grey upper ls-1 ${color}`}>get it on</h5>
            <h3 className={`c-dark ls-2 ${color}`}>Login</h3>
          </div>
        </div>
      </a>
      <a href='https://app.likev3.com/register' className={`download-button download-button-apple ${buttonType}`}>
        <div className={`download-button-inner ${color}`}>
          <div className={`download-button-icon c-red ${color}`}>
            <i className='fa fa-user-plus'></i>
          </div>
          <div className='download-button-content'>
            <h5 className={`c-grey upper ls-1 ${color}`}>get it on</h5>
            <h3 className={`c-dark ls-2 ${color}`}>Register</h3>
          </div>
        </div>
      </a>
    </div>
  )
}

export default DownloadButton
